import Image from "next/image"
import { getAvatarUrl } from "@/lib/utils/getAvatarUrl"
import LoadingRotary from "../loading/loading-rotary-new"

function AvatarFlair({
     isPlaying=false
    ,isContributor=false
    ,isBot=false
    ,containerClass='w-8 h-6'
    ,bgClass='w-6 h-6 '
    ,avatarClass='w-6 h-6 mr-2 border-2 border-transparent '
    ,rotaryContainerClass='w-[28px] h-6 mr-3'
    ,rotaryInternalClass='w-8 h-8'
    ,sizes={
         width:25
        ,height:25
        ,quality:50
    }
    ,avatar_url
    ,avatar_url_sfw
    ,bol_nsfw
    ,avatar_crdate
    ,id
    ,user_name
    ,showReminder=false
}) {
    return (<>
    
                            <div className={`${containerClass} cursor-pointer  group`}>

                                {/* AVATAR */}
                                <div 
                                className={`${avatarClass}  flex items-center content-center rounded-full overflow-hidden
                                        ${isPlaying
                                          ? 'animate-spin-slow'
                                          : ''}
                                `}>

                                    
                                  <Image
                                    src={getAvatarUrl(
                                        avatar_url
                                        ,avatar_url_sfw
                                        ,bol_nsfw
                                        ,avatar_crdate
                                        ,''
                                        ,id
                                    )}
                                    alt={user_name}
                                    width={sizes.width}
                                    height={sizes.height}
                                    quality={sizes.quality}
                                    placeholder="empty"
                                    loading="lazy"
                                    layout="intrinsic"
                                    
                                    className={` object-cover rounded-full overflow-hidden flex-none  
                                    
                                    
                                    ${isContributor 
                                      ? 'bg-white group-hover:bg-blue-500'
                                      : isBot
                                        ? 'bg-green-500'
                                        : 'bg-transparent group-hover:bg-gray-400' }
                                    `}
                                    
                                    />
                                </div>

                              
                              </div>
    </>)
}

export default AvatarFlair