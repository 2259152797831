
import AvatarFlair from "@/components/templateux/user/avatar-flair";
import { ProfileTimer } from "./profile-timer";
import AvatarFlairClean from "@/components/templateux/user/avatar-flair-clean";

export default function ProfileAvatar({
   isPlaying
  ,isContributor
  ,shrink=false
  ,start_date
  ,timestamp
  ,placeholder
  ,bol_caster=true
  ,avatar_url
  ,avatar_url_sfw
  ,bol_nsfw
  ,avatar_crdate
  ,id
  ,user_name
  ,isOwner=false
  ,hasData=true
}) {






  return (<>
    <div className={`flex flex-col items-center content-center  justify-center text-center relative group
                    
                  `}>
      <div className="flex flex-col flex-0 bg-red ">
        <div className="block">
            <div className={`rounded-full ${isContributor ? "bg-white" : "bg-gray-500 opacity-90"} p-[2px]`}>
                <div className="flex h-full w-full items-center justify-center back">
              
                    <AvatarFlair
                
                        isPlaying={isPlaying }
                        isContributor={isContributor}
                        avatar_url={avatar_url}
                        avatar_url_sfw={avatar_url_sfw}
                        bol_nsfw={bol_nsfw}
                        avatar_crdate={avatar_crdate}
                        id={id}
                        user_name={user_name}
                        
                        containerClass={``}
                        sizes={{
                                      width: 72
                                      ,height: 72
                                      ,quality: 100
                                      }}
                        bgClass={`w-[36px] sm:w-[72px] h-[36px] sm:h-[72px]`}
                        avatarClass={isContributor 
                                      ? ` w-[36px] sm:w-[48px] h-[36px] sm:h-[48px]`
                                      : ` w-[36px] sm:w-[48px] h-[36px] sm:h-[48px]`
                                    }
                        rotaryContainerClass={``}
                        rotaryInternalClass={``}
                      />

              </div>
              </div>
       
          </div>
          </div>
          {bol_caster &&
          <div>
          
            <ProfileTimer
              shrink={shrink}
              isPlaying={isPlaying}
              start_date={start_date}
              timestamp={timestamp}
            />
            
          </div>
          }
      </div>
      
</>)


}