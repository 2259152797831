export function Arrow({
    color,
    direction,
    size,
    className=""
}) {

    return (<div 
                className={className}
                style={{
                borderWidth: `${size}px`,
                borderLeft: `solid ${direction=="right" ? color : 'transparent'} ${size}px`,
                borderRight: `solid ${direction=="left" ? color : 'transparent'} ${size}px`,
                borderTop: `solid ${direction=="down" ? color : 'transparent'} ${size}px`,
                borderBottom: `solid ${direction=="up" ? color : 'transparent'} ${size}px`,
                width: `${size*2}px`,
                height: `${size*2}px`,
                display: `inline-block`,
                padding: `0px`
                }} 
            />)
}