import { block_array_seconds } from "../config"
import { countUpSecondsOnly } from "./countUp"
import { IsJsonValid } from "./isJsonValid"


export function isBlocked( 
                      block_json
                    , timestamp
                    , user_name) {

                         

    const validated_block_json = IsJsonValid(block_json) ? JSON.parse(block_json) : []
    const filtered_json = validated_block_json.filter((e)=>e?.name?.toLowerCase() == user_name?.toString()?.toLowerCase())   

    //return immediately if user is not in block_json
    if (filtered_json?.length <= 0) return false

    //console.log("filtered_json?.length",filtered_json,id,"test")

    var ban_json = filtered_json.filter((e)=>e?.type?.toString() == '0') //permaban status type

    //return immediately if user is perma banned
    if (ban_json?.length >= 1) return true

    if (countUpSecondsOnly(filtered_json[0]?.timestamp,timestamp) <= block_array_seconds(filtered_json[0]?.type)) {
        return true
    }
    
    return false
}


export function isBlockedRemaining( 
                      block_json
                    , timestamp
                    , id) {

    const validated_block_json = IsJsonValid(block_json) ? JSON.parse(block_json) : []
    const filtered_json = validated_block_json.filter((e)=>e?.id?.toString() == id?.toString())

    //return immediately if user is not in block_json
    if (filtered_json?.length <= 0) return false

    var ban_json = filtered_json.filter((e)=>e?.type?.toString() == '0') //permaban status type

    //return immediately if user is perma banned
    if (ban_json?.length >= 1) return " ∞"

    if (countUpSecondsOnly(filtered_json[0]?.timestamp,timestamp) <= block_array_seconds(filtered_json[0]?.type)) {
        return ` ${Math.round((block_array_seconds(filtered_json[0]?.type) - countUpSecondsOnly(filtered_json[0]?.timestamp,timestamp)) / 60)} minutes remaining`
    }
    
    return false
}