import Router from 'next/router'
import { config } from 'lib/config'
import { hasData } from '../hasData'

/* PARAMS
@userjson obj (comes from user cookie)
@itemjson obj (comes from data results we are verifying against user cookie)
@redirect boolean, (optional - do we redirect this user if they dont belong? default is true. set to false to show an onscreen message for example.)
@redirecturl str (optional - if passed we will redirect the user to it, otherwise it goes to the default security url)
*/

function parseUserJson(userjson) {  

  if (!hasData(userjson)) return false;
  var userdata = JSON.parse(userjson);
  if (userdata) return (userdata);
  return false;
}

export default parseUserJson