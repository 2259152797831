
import { useEffect, useRef, useState } from "react";
import SvgJsx from "@/components/templateux/svg/svg-jsx";
import * as React from "react";

import { NOTIFICATION_PERMS,  user_settings } from "@/lib/config";

import { Arrow } from "@/components/templateux/arrow";
import BitwisePermissions from "@/components/templateux/sortable-form/users/bitwise-permissions";
import SelectListItem from "@/components/templateux/select-list-item";
import ClientWrapperForReactPopup from "@/components/templateux/popup/ClientWrapperForReactPopup";
import { hasData } from "@/lib/utils/hasData";
import { getBitwise } from "@/lib/utils/bitoperations";

export default function ProfileFollow({
     classColor
    ,classSize=`text-xs sm:text-xl sm:font-bold`
    ,onClick
    ,notifyHandler
    ,message
    ,ownerdata
    ,show_options=false
    ,_bol_notify_options
    ,set_bol_notify_options
    ,_notify_options_bitarray
    ,set_notify_options_bitarray
    ,_notify_show_array
    ,set_notify_show_array
    ,showCondition=true
    ,shrink=true
}) {

  const ref = useRef(null);
  const [changes,set_changes] = useState(false)
  const [submitting,set_submitting] = useState(false)
  const [show_open,set_show_open] = useState(false)

  useEffect(()=>{
    if (changes && hasData(ownerdata?.id)) {
      notifyHandler(ownerdata?.id,ownerdata?.user_name,_bol_notify_options,_notify_options_bitarray,_notify_show_array)
    }
  },[
    _bol_notify_options,
    _notify_options_bitarray,
    _notify_show_array
  ])

  if (!showCondition) return (<></>)
  
    return (<>
                 <div className={`flex-0 sm:flex-1 flex items-center content-center`}
                 
                 >
                        <div className="ml-2 sm:ml-5 flex-1 flex items-center content-center">
                          <div className={`flex items-center content-center shadow-sm hover:bg-[#ffffff77] ${classColor} 
                           ${classSize}
                            flex-1   rounded-md shadow-md cursor-pointer text-center  
                            
                             block 
                            
                            `}
                           
                          >
                            <div 
                              className={show_options ? " pl-2 pr-1 sm:pl-3 sm:pr-2  " : " px-2 sm:px-3 py-1  "}
                              onClick={()=>{
                                onClick();
                                if (!show_options) {
                                  if (ref.current) ref.current.open();
                                  set_show_open(true);
                                } else {
                                 if (ref.current) ref.current.close();
                                  set_show_open(false);
                                }
                              }}
                            >
                              {message}
                            </div>
                           
                           
                            <div className="block">
                              <ClientWrapperForReactPopup
                                  open={show_open}
                                  trigger={<div>
                                      {!show_options 
                                      ? <div className=""></div>
                                      : <div 
                                          aria-haspopup="true"
                                          className={`menu flex items-center content-center relative flex-0 group py-1 ${!shrink ? "sm:py-2" : ""} rounded-r-md 
                                                    ${!show_options
                                                      ? "bg-gray-400" 
                                                      : ""} hover:bg-gray-400  border-l border-gray-900`}
                                          >
                                        <div 
                                          
                                          className="menu-item flex-0 sm:marker:px-1 flex items-center content-center "
                                          > 
                                            <SvgJsx 
                                              type='fill' 
                                              icon={_bol_notify_options == 1 
                                                      || (_bol_notify_options == "2" 
                                                          &&  (getBitwise(_notify_options_bitarray,Object.keys(NOTIFICATION_PERMS(ownerdata?.user_name).FOLLOW))[`broadcast`] 
                                                              || getBitwise(_notify_options_bitarray,Object.keys(NOTIFICATION_PERMS(ownerdata?.user_name).FOLLOW))[`content`]
                                                              )
                                                          ) 
                                                      ? "bell-sm" 
                                                      : "bell-slash-sm"}
                                              className={`cursor-pointer  flex-0 ml-0.5 mr-1 rounded-md p-0.5 sm:p-0 w-4 h-4 ${!shrink ? "sm:w-5 sm:h-5" : ""}
                                              ${open ? "text-white" : "text-gray-200"}  group-hover:text-white`} 
                                              title='More'
                                            />  
                                          </div>
                                        </div>}
                                  </div>
                                  
                                  }

                                   

                                   
                                  position="left top"
                                  on="click"
                                  closeOnDocumentClick
                                  closeOnEscape
                                  mouseLeaveDelay={300}
                                  mouseEnterDelay={0}
                                  contentStyle={{ padding: '0px', border: 'none',backgroundColor: 'transparent', zIndex: 999, boxShadow: 'none'  }}
                                  arrow={false}
                                  nested
                                  forwardRef={ref}
                                  
                                >
                                  <div className={`menu absolute flex flex-col gap-y-1  shadow-modal p-2 mt-[48px] z-1 w-[240px] 
                                  
                                              ${!show_options 
                                                ? "" 
                                                : "sm:-mr-[36px]"}
                                               rounded-md 
                                                bg-gray-500`}
                                  >
                                    <div className=" relative">
                                      <div className={`absolute -top-[26px] right-[10px]`}>
                                        <Arrow
                                          color={'#6B7280'} //show_options ? `#9CA3AF` : `#6B7280`}
                                          direction={`up`}
                                          size={10}
                                        />
                                      </div>
                                    </div>


                                    <div className="flex items-center content-center">
                                      <div className="text-xs font-bold flex-1">
                                        {ownerdata?.user_name} notifications
                                      </div>
                                      {/* <div>
                                        <button
                                          className="text-2xs border rounded-md px-2 py-0.5 border-gray-700 hover:border-white"
                                          onClick={()=> {
                                            if (ref.current) ref.current.close();
                                            set_show_open(false);
                                          }}
                                        >
                                          close
                                        </button>
                                      </div> */}
                                    </div>
                                    

                                    <div>

                                      <div className="text-xs">
                                        {user_settings.bol_notify_options.map((e,index) => (
                                                  
                                                  <SelectListItem 
                                                    key={index}
                                                    set_selected_value={()=>{
                                                      set_bol_notify_options(user_settings.bol_notify_options[index][0]);
                                                      set_changes(true);
                                                    }}
                                                    selected_value={_bol_notify_options}
                                                    value={user_settings.bol_notify_options[index][0]}
                                                    text={<>{user_settings.bol_notify_options[index][1]}</>}
                                                    iconClassName={`h-4 w-4 `}
                                                    itemClassName={`my-2 rounded-md px-4 py-2 flex items-top content-top group  hover:border-white`}
                                                    selectedClassName={`bg-blue-600 hover:bg-blue-500 border border-transparent shadow-md`}
                                                    unselectedClassName={` border border-gray-600  `}
                                                    iconIcon='check'
                                                    iconType='outline'
                                                  />
                                                ))}
                                      </div>

                                          {_bol_notify_options?.toString() == '2' &&
                                                <>
                                                
                                                <div className=" -mt-[8px] p-2 pb-0.5 rounded-b-md bg-[#00000033]">

                                                  <BitwisePermissions
                                                    value={_notify_options_bitarray}
                                                    set_value={set_notify_options_bitarray}
                                                    index={0}
                                                    type="list-small"
                                                    perms={NOTIFICATION_PERMS(ownerdata?.user_name).FOLLOW}
                                                    perms_title={`Get notified when:`}
                                                    set_changes={set_changes}
                                                  />

                                                </div>
                                                
                                                
                                                </>}

                                    

                                        

                                        {/* <div>
                                          
                                            <SettingsSave
                                              submitting_state={submitting}
                                              changes={changes}
                                              className={`w-full whitespace-nowrap text-md inline-flex items-center justify-center px-4 py-2 border border-red-500 rounded-lg shadow-md  font-medium text-white bg-red-500 hover:bg-red-600 bg-gradient-to-b from-red-600  to-red-500  hover:to-red-500 text-center flex-0`}
                                            />
                                        </div> */}

                                    </div>
                                  


                                  </div>
                                </ClientWrapperForReactPopup>
                            </div>
                            

                          </div>
                          
                        </div>
                        
                      </div>
            </>)
}

