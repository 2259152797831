
import { useEffect } from "react";
import { countUp } from "@/lib/utils/countUp";
import { hasData } from "@/lib/utils/hasData";
import useTimer from 'easytimer-react-hook';

export function ProfileTimer({
    shrink
    ,isPlaying
    ,start_date
    ,timestamp
}) {

    return (<div className={` cursor-pointer text-3xs text-center whitespace-nowrap py-0 mt-0.5 rounded-full min-w-[40px]
                    
                    ${isPlaying 
                      ? ' text-white gr bg-red-600  group-hover:bg-red-500' 
                      : `bg-gray-500 text-gray-200 group-hover:bg-gray-400 group-hover:text-white`
                    }`}
        >
                
                  <div className='flex items-center content-center'>
                    <div 
                      className='flex-1 px-2 '
                     
                    >
                        {isPlaying ? 'LIVE' : 'OFF AIR'}
                    </div>
                    
                  </div>
                  
      </div>
    )
}