import { bitval, delBitwise, getBitwise } from "@/lib/utils/bitoperations";
 import { useState } from "react";
import SvgJsx from "../../svg/svg-jsx";

function BitwisePermissions({
     value
    ,set_value
    ,index=0
    ,perms
    ,perms_title
    ,type='buttons'
    ,set_changes=(n)=>{}
}) {

    const [max_length] = useState((2**Object.keys(perms).length)-1);

    const set_bitarray_value = (index,val) => {
        set_value(val)
        return
      }


    return (<>
 
            <HeaderNormal
                perms_title={perms_title}
                value={value}
                max_length={max_length}
                set_bitarray_value={set_bitarray_value}
                index={index}
                type={type}
            />
                  
                  
               


       
    {type == "buttons" &&
    <>
        {Object.keys(perms).map((e,i)=> {
            return <SelectButtons
                key={i}
                value={value}
                set_bitarray_value={set_bitarray_value}
                name={e}
                perms={perms}
                index={index}
                set_changes={set_changes}
            />
        })}
    </>
    }

    {type == "list" &&
    <>
        {Object.keys(perms).map((e,i)=> {

            return (<SelectRows
                        key={i}
                        value={value}
                        set_bitarray_value={set_bitarray_value}
                        name={e}
                        perms={perms}
                        index={index}
                        set_changes={set_changes}
                    />
            )
        })}
    </>
    }

    {type == "list-small" &&
    <>
        {Object.keys(perms).map((e,i)=> {

            return (<SelectRowsSmall
                        key={i}
                        value={value}
                        set_bitarray_value={set_bitarray_value}
                        name={e}
                        perms={perms}
                        index={index}
                        set_changes={set_changes}
                    />
            )
        })}
    </>
    }

    </>)

}

function HeaderNormal({
    perms_title,
    value,
    max_length,
    set_bitarray_value,
    index,
    type
}) {

    //small only
    if (type=="list-small") {
        return (<>
            <div className="flex items-center content-center ">
                   <div className={`flex-1 text-xs font-bold`}>
                       {perms_title}
                   </div>
                   <div className="flex-0 ">
                        {/* SELECT ALL */}
                       <div 
                                   className={` px-2 py-1 border   text-center text-xs rounded-md cursor-pointer whitespace-nowrap 
                                  
                                   border-gray-400 hover:border-white
                                   `}
                                   onClick={()=>{
                                       if (value<max_length) {
                                           set_bitarray_value(index,max_length)
                                       } else {
                                           set_bitarray_value(index,0)
                                       }
                                   }}
                               >
                           {value<max_length ? 'Enable All ' : 'Disable All'} 
                       </div>
                   </div>
               
             </div>
    </>)
    }

    //all other types
    return (<>
                 <div className="flex items-center content-center mt-5">
                        <div className={`flex-0 font-bold text-lg`}>
                            {perms_title}
                        </div>
                        <div className="flex-0 ml-2">
                             {/* SELECT ALL */}
                            <div 
                                        className={` m-1 p-1 border   text-center text-xs rounded-md cursor-pointer whitespace-nowrap 
                                        w-20
                                        border-gray-400 hover:border-white
                                        `}
                                        onClick={()=>{
                                            if (value<max_length) {
                                                set_bitarray_value(index,max_length)
                                            } else {
                                                set_bitarray_value(index,0)
                                            }
                                        }}
                                    >
                                {value<max_length ? 'Enable All ' : 'Disable All'} 
                            </div>
                        </div>
                    
                  </div>
    </>)
}

function SelectButtons({
     set_bitarray_value
    ,value
    ,name
    ,perms
    ,index
    ,set_changes
}) {

    const perms_keys = Object.keys(perms)

    return (<>

            
                <div 
                    className={`inline-flex items-center content-center m-1 p-2  text-sm rounded-md cursor-pointer whitespace-nowrap
                                ${getBitwise(value,perms_keys)[name] 
                                    ? 'bg-blue-400 hover:bg-blue-500 text-white'
                                    : 'bg-gray-300 hover:bg-gray-400 text-gray-600 hover:text-white'
                                }
                    `}
                    onClick={()=>{
                        set_bitarray_value(index,delBitwise(value,bitval(perms_keys.indexOf(name))));
                        set_changes(true)
                    }}
                >
                
                    <div className="flex-0 mr-1">
                         <SvgJsx 
                            type='fill' 
                            icon={!getBitwise(value,perms_keys)[name] ? `x-circle-sm` : `check-circle-sm`}
                            className={` w-4 h-4 flex-0 my-auto
                            ${!getBitwise(value,perms_keys)[name] ? 'text-gray-100' : ''}     
                            `}
                            title={!getBitwise(value,perms_keys)[name] ? `disabled` : `enabled`}
                            />
                    </div>
                    <div className="flex-0">{perms[name]?.name}</div>
                </div>
            
            
    </>)
}

function SelectRows({
     set_bitarray_value
    ,value
    ,name
    ,perms
    ,index
    ,set_changes
 }) {

    const perms_keys = Object.keys(perms)
  
   return (<>

                <div 
                    className={`my-2 rounded-md px-2 py-2 cursor-pointer flex items-top content-top group hover:shadow-md
                                ${getBitwise(value,perms_keys)[name] 
                                    ? `bg-blue-600 hover:bg-blue-500` 
                                : `bg-gray-700 hover:bg-gray-600`} 
                                    text-gray-200
                                `}
                    onClick={()=>{
                        set_bitarray_value(index,delBitwise(value,bitval(perms_keys.indexOf(name))));
                        set_changes(true)
                    }}
                >
                
                    <div className={`flex-0 w-7 
                                     ${getBitwise(value,perms_keys)[name] 
                                     ? ` opacity-100 ` 
                                     : ` opacity-50 group-hover:opacity-80 `
                                     }`}>
                                        <SvgJsx 
                                            type={'outline'}
                                            icon={getBitwise(value,perms_keys)[name] ? `checkbox-full` : `checkbox-empty`}
                                            className={`h-7 w-7 mr-2 `}
                                            title={getBitwise(value,perms_keys)[name] ? `selected` : `not selected`}
                                        />
                         
                    </div>
                    
                    <div className="ml-2 flex-1">
                        {perms[name]?.description}
                     </div>
                   
                </div>








           </>)
 }

 
function SelectRowsSmall({
    set_bitarray_value
   ,value
   ,name
   ,perms
   ,index
   ,set_changes
}) {

   const perms_keys = Object.keys(perms)
 
  return (<>

               <div 
                   className={`my-2 rounded-md px-2 py-2 cursor-pointer flex items-top content-top group  border 
                               ${getBitwise(value,perms_keys)[name] 
                                   ? `bg-blue-600 hover:bg-blue-500 font-bold border-transparent hover:border-white ` 
                               : ` hover:border-white border-gray-500 `} 
                                   text-gray-200
                               `}
                   onClick={()=>{
                       set_bitarray_value(index,delBitwise(value,bitval(perms_keys.indexOf(name))));
                       set_changes(true)
                   }}
               >
               
                   <div className={`flex-0 w-4 
                                    ${getBitwise(value,perms_keys)[name] 
                                    ? ` opacity-100 ` 
                                    : ` opacity-50 group-hover:opacity-80 `
                                    }`}>
                                       <SvgJsx 
                                           type={'outline'}
                                           icon={getBitwise(value,perms_keys)[name] ? `checkbox-full` : `checkbox-empty`}
                                           className={`h-4 w-4 mr-2 `}
                                           title={getBitwise(value,perms_keys)[name] ? `selected` : `not selected`}
                                       />
                        
                   </div>
                   
                   <div className="ml-2 flex-1 text-xs">
                       {perms[name]?.description}
                    </div>
                  
               </div>








          </>)
}



export default BitwisePermissions